
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import Pagination from "@/components/mini/Pagination.vue";
import CountSelector from "@/components/mini/CountSelector.vue";
import { onBeforeMount, ref, defineComponent } from "vue";
import { apiGet } from "@/api";
import { useRoute } from "vue-router";
import { Toast } from "@/utils/notify";
import ActivitiesRow from "@/views/activities/constituent/ActivitiesRow.vue";
// import TransactionRow from "@/views/withdrawals/constituent/TransactionRow.vue";
import DataFilterSelector from "@/components/selector/DataFilterSelector.vue";

export default defineComponent({
  name: "Withdrawals",
  components: {
    DataFilterSelector,
    ActivitiesRow,
    HourglassLoader,
    Pagination,
    CountSelector,
  },
  setup() {
    const loading = ref(false);
    const data = ref(null);
    const filters = {
      ALL: "All Activities",
      SUBMITTED: "Submitted/New",
      CANCELED: "Canceled",
      PROCESSING: "Processing",
      DONE: "Done/Success",
      FAILED: "Done/Failed",
    };
    const status = ref(undefined);
    const route = useRoute();

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/admin/user-account/activities`,
          Object.assign(
            ((data.value as unknown) as Record<string, unknown>)?.meta ?? {},
            {
              status: status.value === "ALL" ? undefined : status.value,
            }
          )
        );
        data.value = response.data.data;
        console.log(data.value);
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);

    return {
      RefreshData,
      route,
      data,
      loading,
      filters,
      status,
    };
  },
});
