<template>
  <tr>
    <template v-if="loading">
      <td colspan="8" class="text-center">
        <span class="font-weight-bolder" style="font-size: 1.5em">Loading ...</span>
      </td>
    </template>
    <template v-else>
      <td>
        <a class="font-weight-bold">{{ dt.id }}</a>
      </td>
      <td>
        <span class="font-weight-normal">{{ dt.description }}</span>
      </td>
      <td>{{ dt.type }}</td>
      <td>
        <span
          class="font-weight-normal"
        >{{ dt.User.Profile.first_name }} {{ dt.User.Profile.last_name }}</span>
      </td>
      <td>
        <span class="font-weight-normal">{{ dt.User.username }}</span>
      </td>
      <td>
        <span class="font-weight-normal">{{ dt.User.type }}</span>
      </td>
      <!-- <td>
        <router-link :to="`/activities/${dt.id}`" class="font-weight-normal">{{ dt.status }}</router-link>
      </td>-->
      <td>
        <span class="font-weight-normal">{{ formatDate(dt.created_at) }}</span>
      </td>
      <!-- <td>
        <div class="dropdown">
          <button
            class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            :disabled="loading"
          >
            <span class="icon icon-sm">
              <span class="fas fa-ellipsis-h icon-dark"></span>
            </span>
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <div class="dropdown-menu">
            <router-link :to="`/activities/${dt.id}`" class="dropdown-item text-dark">
              <span class="fas fa-eye mr-1"></span>
              See Details
            </router-link>
          </div>
        </div>
      </td>-->
    </template>
  </tr>
</template>

<script lang="ts">
import moment from "moment";

import { inject, ref, defineComponent } from "vue";
import { apiPost } from "@/api";
import { ShowSuccess, Toast } from "@/utils/notify";

export default defineComponent({
  name: "ActivityRow",
  props: {
    dt: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const RequestConfirmation = inject("RequestConfirmation");

    const loading = ref(false);

    const formatDate = (date: string) => {
      return moment(date).format("LLLL");
    };

    const ToggleAdmin = async () => {
      loading.value = true;
      try {
        await apiPost(
          `/admin/user-account/${
            props.dt.type === "BASIC_USER" ? "add-admin" : "remove-admin"
          }`,
          {
            userId: props.dt.id,
          }
        );
        // eslint-disable-next-line vue/no-mutating-props
        props.dt.type =
          props.dt.type === "BASIC_USER" ? "ADMIN_USER" : "BASIC_USER";
        ShowSuccess();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to toggle admin type: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    return {
      loading,
      RequestConfirmation,
      formatDate,
      ToggleAdmin,
    };
  },
});
</script>

<style scoped></style>
